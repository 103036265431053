<template>
  <div class="margin-top-30 padding-bottom-30">
    <div style="margin: 0 5vw;">
      <div class="detail" v-if="detail.id">
        <div class="detail_left">
          <div class="detail_box">
            <div class="detail_box_title">基础信息</div>
            <div class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">订单状态</div>
                <div class="detail_box_item_right b">{{orderStatusList[detail.status]}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">订单号</div>
                <div class="detail_box_item_right">{{detail.id}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">下单时间</div>
                <div class="detail_box_item_right">{{parseTime(detail.createTime)}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">支付方式</div>
                <div class="detail_box_item_right">{{detail.payStatus === 1 ? '微信支付':''}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">支付时间</div>
                <div class="detail_box_item_right">{{detail.payTime ? parseTime(detail.payTime) : ''}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">支付单号</div>
                <div class="detail_box_item_right">{{detail.outTradeNo}}</div>
              </div>
            </div>
          </div>
          <div class="detail_box">
            <div class="detail_box_title">订单商品</div>
            <div class="">
              <div class="detail_box_item_goods_wap" v-if="detail.orderGoodsList && detail.orderGoodsList.length">
                <div class="detail_box_item_goods" v-for="goods in detail.orderGoodsList">
                  <div class="goods_out_left">
                    <el-image style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                  </div>
                  <div class="goods_out_right">
                    <div class="out_right_left">
                      <div class="goods_name">{{goods.goodsName}}</div>
                      <div class="gray">{{goods.skuName}}</div>
                    </div>
                    <div class="out_right_right">
                      <div class="goods_price">¥{{goods.price}}</div>
                      <div class="gray"> x{{goods.goodsNum}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">商品总价</div>
                <div class="detail_box_item_right">¥{{detail.goodsPrice}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">运费</div>
                <div class="detail_box_item_right">¥{{detail.expressFee}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">优惠金额</div>
                <div class="detail_box_item_right">¥{{detail.discountAmount}}</div>
              </div>
              <div class="detail_box_item">
                <div class="" style="line-height: 24px;">
                  <span class="">总1件，应付款</span> <span class="f20 b">￥{{detail.realAmount}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="detail_box">
            <div class="detail_box_title">退货/售后</div>
            <div class="divide-list" style="margin-bottom: 10px;"
                 v-if="detail.orderRefundList && detail.orderRefundList.length" v-for="refund in detail.orderRefundList">
              <div class="detail_box_item">
                <div class="detail_box_item_left">售后单号</div>
                <div class="detail_box_item_right">
                  <span class="margin-right-10">{{refund.id}}</span><el-button size="medium" type="text" @click="toShowRefundDetail(refund.id)">详情</el-button>
                </div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">退款商品</div>
                <div class="detail_box_item_right">
                  <div class="detail_box_item_goods">
                    <div class="goods_out_left">
                      <el-image style="width: 62px; height: 62px" :src="refund.cover"></el-image>
                    </div>
                    <div class="goods_out_right">
                      <div class="out_right_left">
                        <div class="goods_name">{{refund.goodsName}}</div>
                        <div class="gray">{{refund.skuName}}</div>
                      </div>
                      <div class="out_right_right">
                        <div class="goods_price">¥{{refund.price}}</div>
                        <div class="gray">x{{refund.applyNum}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">售后类型</div>
                <div class="detail_box_item_right pull_right red">{{refundType[refund.type]}}</div>
              </div>
              <div class="detail_box_item" v-if="refund.type === 1">
                <div class="detail_box_item_left">货物状态</div>
                <div class="detail_box_item_right pull_right">{{refundGoodsExpressStatus[refund.goodsExpressStatus]}}</div>
              </div>
              <div class="detail_box_item" v-if="false">
                <div class="detail_box_item_left">退款原因</div>
                <div class="detail_box_item_right pull_right">{{refund.reason}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">申请内容</div>
                <div class="detail_box_item_right pull_right">{{refund.remark}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">退款时间</div>
                <div class="detail_box_item_right">{{parseTime(refund.refundSuccessTime)}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">退款状态</div>
                <div class="detail_box_item_right">{{refundStatus[refund.status]}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail_right">
          <div class="detail_box">
            <div class="detail_box_title">发货信息</div>
            <div class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">收货人</div>
                <div class="detail_box_item_right">{{detail.receiveName}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">联系方式</div>
                <div class="detail_box_item_right">{{detail.receivePhone}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">收货地址</div>
                <div class="detail_box_item_right">{{detail.receiveAddressInfo}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">买家留言</div>
                <div class="detail_box_item_right red f16 b">{{detail | remark}}</div>
              </div>
            </div>
          </div>
          <div class="detail_box" >
            <div class="detail_box_title">物流信息</div>
            <div class="" v-if="detail.expressList && detail.expressList.length > 0" >
              <template v-for="(express) in detail.expressList">
                <div class="divide-list" >
                  <div class="detail_box_item" v-if="detail.expressList.length > 1 && express.orderGoods.length">
                    <div v-for="og in express.orderGoods" style="position: relative">
                      <el-image class="margin-left" style="width: 62px; height: 62px" :src="og.cover" :title="`品名：${og.goodsName} ，规格:${og.skuName} ， 数量:${og.num} `"></el-image>
                      <div style="position: absolute;bottom: 0;right: 0;color: white;background-color: #f56c6c;border-radius: 30px;padding: 0 5px;font-size: 10px;" title="数量">{{og.num}}</div>
                    </div>
                  </div>
                  <div class="detail_box_item">
                    <div class="detail_box_item_left">{{express.expressName}}</div>
                    <div class="detail_box_item_right red f16 b">{{express.expressSn}}</div>
                    <div class="" v-if="!express.hasLoadData"><el-button type="text" @click="showExpressTimeLine(express)">查看</el-button></div>
                    <div class="" v-else><el-button type="text" @click="express.foldExpress = !express.foldExpress"> {{!express.foldExpress?'折叠':'展开'}} </el-button></div>
                  </div>
                  <div class="padding-right" :style="!express.foldExpress?'height:100%;':'height:100px;overflow: hidden;'">
                    <el-timeline >
                      <el-timeline-item v-for="(activity, index) in express.expressDetailList" :color="activity.color" :key="index"
                                        :timestamp="activity.time">{{activity.context}}
                      </el-timeline-item>
                    </el-timeline>
                  </div>
                </div>
              </template>
            </div>
            <div class="detail_box_item" v-else>{{detail.expressWay === 2 ? '县域配送':detail.expressWay === 3 ? '到店自提' : '暂无物流信息'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-action-right-bottom edit-goods-action">
      <el-button type="" @click="goBack">
        <el-icon class="el-icon-back"/>
        返回上页
      </el-button>
    </div>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {parseTime, goBack} from '../../../assets/js/utils.js'
  import {orderStatusList,refundStatus,refundType,refundGoodsExpressStatus} from '../../../assets/js/status.js'

  export default {
    name: "orderDetail",
    filters: {
        remark(data) {
            if (data.orderType === 4 && data.remark) {
                return JSON.parse(data.remark).remark;
            } else {
                return data.remark;
            }
        }
    },
    data() {
      return {
        orderStatusList,
        refundStatus,
        refundType,
        refundGoodsExpressStatus,
        detail: {},
      }
    },
    created() {
      let orderId = this.$route.query.orderId;
      if (orderId) {
        this.showDetail(orderId)
      }
    },
    methods: {
      parseTime,
      goBack,
      showDetail(orderId) {
        this.axios.get(URL.supplierOrder.info, {params: {orderId: orderId}}).then(res => {
          if (res.data) {
            let detail = res.data;
            if (detail.orderRefundList){
              detail.orderRefundList.forEach(item=>{
                if(item.orderGoodsJson){
                  let orderGoods = JSON.parse(item.orderGoodsJson);
                  if(orderGoods){
                    item.cover = orderGoods.cover;
                    item.goodsName = orderGoods.goodsName;
                    item.skuName = orderGoods.skuName;
                    item.price = orderGoods.price;
                  }
                }
              })
            }
            if (detail.expressList){
              detail.expressList.forEach(item=>{
                item.hasLoadData = false;
                item.foldExpress = false;
                item.orderGoods = JSON.parse(item.orderGoodsJson);
                this.showExpressTimeLine(item);//自动触发去查物流
              });
            }
            this.detail = detail;
          }
        })
      },
      showExpressTimeLine(express){
        if(!express.hasLoadData){//接口加载数据
          let data = {
            expressSn:express.expressSn,
            phone:express.receivePhone,
          };
          this.axios.get(URL.supplierOrder.express,{params:data}).then(res=>{
            if(res.data && res.data.expressDetails){
              try{
                let ed = JSON.parse(res.data.expressDetails);
                if(ed.data.length > 1){
                  ed.data[0].color = '#0bbd87';
                }
                express.expressDetailList = ed.data;//context 和 time
              }catch (e) {

              }
            }
          }).finally(()=>{
            express.hasLoadData = true;
            express.foldExpress = true;
          })
        }else {//收起或者展开
          console.log("收起或者展开")
          if(!express.foldExpress){
            express.foldExpress = true;
          }else {
            express.foldExpress = false;
          }
        }
      },
      toShowRefundDetail(id){
        this.$router.push({
          path:'/orderRefundDetail',
          query:{
            refundId:id,
          }
        });
      },
    },
  }
</script>

<style lang="less" scoped>
  @import "../../../assets/css/order";
</style>
